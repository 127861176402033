<router>
{
    name: 'Downloads',
}
</router>
<template lang="pug">
v-container
    PortalDownloads

</template>
<script>

import PortalDownloads from '@/components/portal/downloads';
// import UserList from '~/components/salestool/userlist.vue';
export default {
    meta: {
        // role: 'portaldownloads'
    },
    components: {
        PortalDownloads
    },
    data () {
        return {
        };
    },
    methods: {

    },
    computed: {

    },
    mounted () {
        window.portaldownloads = this;
    }
};
</script>

<style scoped>
</style>
